@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  h1 {
    @apply text-3xl heading;
  }
  h2 {
    @apply text-xl heading;
  }
  h6 {
    @apply text-lg heading;
  }
  p {
    @apply font-body text-lg;
  }
}

body {
  @apply bg-primary text-gray-100 font-body text-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
  @apply font-display font-semibold text-gray-100;
}

.btn {
  @apply bg-subtleAccent hover:bg-darkSubtleAccent p-3 font-body rounded shadow-md font-medium focus:outline-none focus:ring-2 ring-brightAccent ring-opacity-80 cursor-pointer z-10 flex items-center transition-all;
}

.round-btn {
  @apply rounded-full bg-subtleAccent hover:bg-darkSubtleAccent p-3 font-body shadow-md font-medium focus:outline-none focus:ring-2 ring-brightAccent ring-opacity-80 cursor-pointer z-10 w-min;
}

.btn-icon {
  @apply text-white inline mr-1;
}

.popper {
  @apply shadow-md;
  border: solid 1px #191829 !important;
}
